
// ------------------------------------------
// Configuration
// ------------------------------------------
const CONFIG = {
    endpoints: {
        save: '/cookie-consent',
        load: '/get-cookie-preferences'
    }
};

// ------------------------------------------
// Preference Templates
// ------------------------------------------
const PREFERENCES = {
    all: {
        enabled: ['necessary', 'experience', 'analytics', 'marketing'],
        disabled: []
    },
    necessary: {
        enabled: ['necessary'],
        disabled: ['experience', 'analytics', 'marketing']
    }
};

// ------------------------------------------
// Preference Management -- Sending Data to Backend
// ------------------------------------------
class PreferenceManager
{
    /**
     * Retrieves the current preferences for cookie categories based on
     * the state of the toggles in the user interface.
     *
     * @return {Object} An object containing 'enabled' and 'disabled' arrays
     *                  which hold the categories of cookies that are enabled
     *                  and disabled respectively.
     */
    getCurrentPreferences()
   {
        const preferences = {
            enabled: ['necessary'], // necessary is always enabled
            disabled: []
        };

        // Check toggle states
       $('.cookie-toggle').each(function () {
            const category = $(this).data('category');
            if (!category) {
                console.warn('Toggle found without category:', this);
                return;
            }

            if (category !== 'necessary') {
                if ($(this).is(':checked')) {
                    preferences.enabled.push(category);
                } else {
                    preferences.disabled.push(category);
                }
            }
        });

        return preferences;
    }

    /**
     * Sends user preferences to the server to be saved.
     *
     * @param {Object} preferences - The user preferences to be serialized and sent.
     * @return {void}
     */
    serializePreferences(preferences)
    {
        const data = {preferences: preferences};

        $.ajax({
            url: CONFIG.endpoints.save,
            type: 'POST',
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(data),
            success: function (response) {
                // Reload the page on success
                if (response.status === 'success') {
                    window.location.reload();
                }
            },
            error: function (xhr, status, error) {
                console.error('Failed to save preferences:', xhr.responseText || error);
            }
        });
    }

    /**
     * Loads user preferences from the server and applies them to the application.
     *
     * Makes an AJAX GET request to retrieve user preferences.
     * If the request is successful and preferences are returned, the method applies these preferences.
     * Logs an error message to the console if the request fails.
     *
     * @return {void}
     */
    loadUserPreferences()
    {
        const thisObj = this;

        $.ajax({
            url: CONFIG.endpoints.load,
            type: 'GET',
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            success: function (response) {
                if (response.status === 'success' && response.preferences) {
                    // Update all toggle states based on enabled preferences
                    thisObj.applyPreferences(response.preferences);
                }
            },
            error: function (xhr, status, error) {
                console.error('Failed to load preferences:', error);
            }
        });
    }

    /**
     * Applies user preferences to cookie toggles.
     *
     * @param {Object} preferences - The user preferences object.
     * @param {Array} preferences.enabled - List of enabled cookie categories.
     *
     * @return {void}
     */
    applyPreferences(preferences)
    {
        $('.cookie-toggle').each(function () {
            const category = $(this).data('category');
            if (category && category !== 'necessary') {
                const isEnabled = preferences.enabled.includes(category);
                $(this).prop('checked', isEnabled);
            }
        });
    }

}

// ------------------------------------------
// UI Management -- Frontend
// ------------------------------------------
class UIManager
{
    /**
     * Initializes the UI Manager with necessary DOM elements and preference management.
     *
     * @return {void} This constructor does not return a value.
     */
    constructor()
    {
        this.preferenceManager = new PreferenceManager();

        // Defining Dom Elements used in UI Manager
        this.form = $('#cookiePreferencesForm');
        this.preferenceIcon = $('#cookie-preferences-icon');
        this.banner = $('#cookie-banner');
        this.customizeModal = $("#customizeModal");

        // Initialize banner if present
        if (this.banner.length) {
            this.initCookieBanner();
        } else if (this.preferenceIcon.length) {
            // Initialize preferences icon if present (when consent was given)
            this.initPreferencesIcon();
        }

        // Initialize settings page if present
        if (this.form.length) {
            this.initProfileCookieSettings();
        }
    }

    /**
     * Handles the cookie consent actions by sending the preferences to back end and handles the UI behavior for the Cookie Consent Banner and modal.
     *
     * @param {string} consentType - The type of consent given, which can be 'preferences' or other predefined consent types.
     * @param {boolean} [isProfile=false] - Flag indicating if the consent action is triggered from a user profile page. Defaults to false.
     * @return {void}
     */
    handleCookieConsent(consentType, isProfile = false)
    {
        if (!isProfile) {
            this.banner.attr("data-action-taken", "true").removeClass("show").addClass("slide-out");
        }

        const preferences = consentType === 'preferences' ? this.preferenceManager.getCurrentPreferences() : PREFERENCES[consentType];

        this.preferenceManager.serializePreferences(preferences);

        if (!isProfile && this.customizeModal.length) {
            this.customizeModal.modal('hide');
        }
    }

    /**
     * Initializes event handlers for modal buttons.
     * This method attaches click event listeners to buttons used for accepting or rejecting cookie consent options.
     * It binds the appropriate handler to each button depending on its purpose.
     *
     * @return {void}
     */
    initModalHandlers()
    {
        const acceptButtons = $("#banner-accept-all, #modal-accept-all");
        const rejectButtons = $("#banner-reject-all, #modal-reject-all");

        acceptButtons.on('click', () => this.handleCookieConsent("all"));
        rejectButtons.on('click', () => this.handleCookieConsent('necessary'));
        $("#submit-preferences").on('click', () => this.handleCookieConsent('preferences'));
    }

    /**
     * Initializes the Preferences Icon if it exists. This method sets up an event listener for the
     * cookie preferences button and displays a modal window when the button is clicked. It also
     * loads user preferences and initializes handlers for the modal.
     *
     * @return {void}
     */
    initPreferencesIcon()
    {
        if (this.preferenceIcon.length) {
            $("#cookie-preference-btn").on('click', (e) => {
                e.preventDefault();
                this.customizeModal.modal('show');
                this.preferenceManager.loadUserPreferences();
                this.initModalHandlers();
            });
        }
    }

    /**
     * Initializes the cookie consent banner by setting up event listeners and
     * modal handlers. When the "customize cookies" button is clicked,
     * the banner slides out and a modal for customizing cookies is shown.
     * Also manages the state of the banner based on modal visibility.
     *
     * @return {void}
     */
    initCookieBanner()
    {
        // Event Listeners
        $('#customize-cookies', this.banner).on('click', () => {
            this.banner.addClass("slide-out");
            this.customizeModal.modal('show');
            this.preferenceManager.loadUserPreferences();
        });

        // Initialize modal handlers
        this.initModalHandlers();

        // Modal close handler
        this.customizeModal.on('hidden.bs.modal', () => {
            if (!this.banner.attr('data-action-taken')) {
                this.banner.removeClass("slide-out").addClass("show");
            }
        });
    }

    /**
     * Initializes the profile cookie settings by loading current user preferences
     * and setting up the form submission handler for updating cookie consent preferences within the users
     * Profile Settings.
     *
     * @return {void}
     */
    initProfileCookieSettings()
    {
        // Load current preferences
        this.preferenceManager.loadUserPreferences();

        // Handle form submission
        $('#profile-submit-preferences').on('click', () => {
            this.handleCookieConsent('preferences', true);
        });
    }

}

// Initialize Cookie Consent into the DOM
document.addEventListener("DOMContentLoaded", function() {
    (new UIManager());
});